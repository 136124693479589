body {
  overflow-y: scroll;
  font-family: 'bonum', Fallback, sans-serif !important;
  scroll-behavior: smooth;
  background-color: #009688;
}

@font-face {
  font-family: 'bonum';
  src: url('fonts/texgyrebonum-regular.otf') format('opentype');
}

@font-face {
  font-family: 'bonum-italic';
  src: url('fonts/texgyrebonum-italic.otf') format('opentype');
}

@font-face {
  font-family: 'bonum-bold';
  src: url('fonts/texgyrebonum-bold.otf') format('opentype');
}

@font-face {
  font-family: 'bonum-bold-italic';
  src: url('fonts/texgyrebonum-bolditalic.otf') format('opentype');
}

.extcontainer {
  display: grid;
  min-height: 100vh;
  background-color: #009688;
  grid-template-rows: auto auto 1fr auto;
}

.container {
  display: grid;
  grid-template-columns: 1fr minmax(1125px,1440px) 1fr;
}

.containerheader {
  display: grid;
  grid-template-columns: 1fr minmax(375px,1550px) 1fr;
  z-index: 2;
  position: sticky;
  top: 0;
}

.containerfooter {
  display: grid;
  grid-template-columns: 1fr minmax(375px,1550px) 1fr;
}

.header {
  background-image: linear-gradient(#00877AE5, #00877AE5);
  color: #FFF;
  padding: 4px;
  grid-template-columns: minmax(210px,210px) auto 1fr;
  display: grid;
  z-index: 2;
}

.sticky {
  position: sticky;
  top: 0;
  background-image: linear-gradient(#009688FF, #009688FF, #00968800);
}

.headerleft {
  padding-left: 12px;
  align-self: center;
}

.headerright {
  padding-right: 12px;
  justify-self: end;
  align-self: center;
}

.greenfill {
  background-color: #009688;
}

.footer {
  border-radius: 16px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.25);
  background-color: #444;
  color: #FFF;
  padding: 10px 20px;
  margin: 2px 0px 14px 0px;
  justify-self: stretch;
}

.content {
  box-shadow: 0 5px 15px rgba(0,0,0,0.25);
  border-radius: 16px;
  margin: 24px 0px;
}

.section {
  background-color: #FFF;
  border-radius: 0px 0px 16px 16px;
  min-height: 50px;
  align-self: center;
}

.section_noimg {
  background-color: #FFF;
  border-radius: 16px;
  min-height: 50px;
  align-self: center;
}

.imgcontainer {
  text-align: center;
  color: #FFF;
  display: grid;
}

.cropped {
  object-fit: none;
  object-position: center;
  height: 524px;
  width: 100%;
  border-radius: 16px 16px 0px 0px;
}

.cropped_demo1 {
  object-fit: none;
  object-position: center;
  height: 430px;
  width: 100%;
  border-radius: 16px 16px 0px 0px;
}


.player-wrapper {
  border-radius: 16px 16px 0px 0px;
  overflow: hidden;
  background-color: #FFF;
}

.player-wrapper-int {
  height: var(--VideoHeight);
  background-image: url('https://www.railmagic.com/web_loading.jpg');
  background-size: cover;
  background-position: center;
}

.react-player {
  position: relative;
  left: var(--VideoLeft);
  top: var(--VideoTop);
  scale: var(--VideoScale);
}


.videol {
  display: none;
}
.videom {
  display: none;
}
.videos {
  display: none;
}

.overview {
  object-fit: none;
  object-position: center;
  height: 378px;
  width: 100%;
  border-radius: 16px 16px 0px 0px;
}

.imgheader {
  font-size: 60px;
  width: 700px;
  align-self: flex-start;
  padding-top: 10%;
}

.imgimage, .imgheader, .imgtext, .imgtextcenter {
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: center;
}

.imgimage {
  background-color: #FFF;
  border-radius: 16px 16px 0px 0px;
  width: 100%;
}

.imgtext {
  font-size: 30px;
  width: 700px;
  align-self: flex-end;
  padding-bottom: 10%;
}

.imgtextcenter {
  font-size: 120px;
  width: 700px;
  align-self: center;
  color: #FFFFFF50;
}

.itemextcontainer {
  grid-template-columns: 1fr minmax(750px,1100px) 1fr;
  display: grid;
  justify-items: center;
}

.itemcontainer {
  grid-template-columns: minmax(375px,480px) minmax(375px,480px);
  display: grid;
  justify-self: center;
}

.itemcontainersingle {
  grid-template-columns: minmax(750px,960px);
  display: grid;
  justify-self: center;
  font-size: 180%;
}

.item {
  min-height: 50px;
  align-self: top;
  padding: 8px;
}

.item.green {
  background-color: #009688;
  color: #FFF;
  padding: 8px;
}

.itemcontent {
  /*background-color: aqua;*/
  margin: 4px;
  justify-self: center;
  text-align: center;
  align-self: center;
}

.spacer {
  margin: 40px 0px;
}

h1 {
  font-size: 32px;
  color: #444;
  margin-left: 5%;
}

h2 {
  font-size: 24px;
  color: #444;
  margin-left: 2%;
}

a {
  text-decoration: none;
}

@media (max-width: 749px) {
  .container {
    grid-template-columns: 1fr minmax(375px,600px) 1fr;
  }
  .itemextcontainer {
    grid-template-columns: 1fr minmax(375px,480px) 1fr;
  }
  .itemcontainer {
    grid-template-columns: minmax(375px,480px);
  }
  .itemcontainersingle {
    grid-template-columns: minmax(375px,480px);
  }
  .first {
    order: -1;
  }
  .headerright {
    /*display: none;*/
  }
  .header {
    grid-template-columns: minmax(210px,210px) 1fr;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 18px;
  }
  .imgheader {
    top: 30%;
    font-size: 32px;
    width: 375px;
  }
  .imgtext {
    top: 85%;
    font-size: 17px;
    width: 375px;
  }
  .imgtextcenter {
    font-size: 60px;
    width: 375px;
  }
  .cropped {
    height: 524px;
  }
  .overview {
    height: 235px;
  }
  .navfloat {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .navsmall {
    display: grid;
  }
  .nomobile {
    display: none;
  }
  .logo {
    width: 80%;
  }
  .cropped_demo1 {
    height: 300px;
    object-fit:cover;
  }
  .videos {
    display: grid;
  }
}


@media (max-width: 1174px) and (min-width: 750px) {
  .container {
    grid-template-columns: 1fr minmax(750px,960px) 1fr;
  }
  .c3full {
    grid-column: span 2;
  }
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 21px;
  }
  .imgheader {
    top: 30%;
    font-size: 50px;
    width: 500px;
  }
  .imgtext {
    top: 85%;
    font-size: 24px;
    width: 500px;
  }
  .imgtextcenter {
    font-size: 90px;
  }
  .videom {
    display: grid;
  }
}

@media (min-width: 1175px) {
  .cropped {
    height: 786px;
  }
  .cropped_demo1 {
    height: 644px;
  }
  .itemcontainer {
    column-gap: 30px;
    row-gap: 10px;
  }
  .videol {
    display: grid;
  }
}

.button {
  justify-self: center;
  align-self: center;
  display: inline-flex;
  align-items: center;
  color: #FFF;
  background-color: #444;
  border: 3px solid #FFF;
  border-radius: 20px;
  padding: 6px;
  margin: 5px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.button:hover {
  border: 3px solid #444;
  background-color: #444;
  padding: 6px;
}

