
.nav {
  border-radius: 16px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.25);
  background-color: #444;
  color: #FFF;
  padding: 0px 20px;
  margin: 14px 0px 2px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.navmenu {
  display: grid;
  grid-template-columns: repeat(3,auto);
  grid-gap: 4px;
  list-style:none;
  align-items: center;
  text-align: center;
  justify-content: end;
  text-decoration: none;
  color: #FFF;
  white-space: nowrap;
  padding: 0px;
}

.nav1 {
  padding: 6px 0px;
  display: flex;
}

.nav2 {
  display: none;
}

.nav3 {
}

@media (max-width: 749px) {
  .nav2 {
    display: flex;
  }
  .nav3 {
    flex-basis: 100%;
  }
  .navmenu {
    grid-template-columns: auto;
    justify-content: center;
    row-gap: 10px;
  }
  .nav {
    display: flex;
    justify-content: center;
  }
}


.menu-item {
  justify-self: center;
  align-self: center;
  display: inline-flex;
  align-items: center;
  border: 1px dashed #FFF;
  border-radius: 10px;
  padding: 3px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.menu-item:hover {
  border: 2px solid #FFF;
  padding: 2px;
}

.footer-item {
  justify-self: center;
  align-self: center;
  display: inline-flex;
  align-items: center;
  border: 1px dashed #FFF;
  border-radius: 10px;
  padding: 3px;
  margin: 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.footer-item:hover {
  border: 2px solid #FFF;
  padding: 2px;
}